import { useChangePassword } from '@mg/api-wrappers/src/api-internal';
import { MGAnimatedIcon } from '@mg/ui/src/components/MGAnimatedIcon';
import { MGFormFieldPassword, MGFormSubmitButton } from '@mg/ui/src/components/MGForm';
import { addErrorsToFormFields } from '@mg/ui/src/components/MGForm/addErrorsToFormFields.ts';
import { MGForm } from '@mg/ui/src/components/MGForm/MGForm.tsx';
import { defaultErrorHandler } from '@mg/ui/src/helpers/errorHandling';
import { Stack, Typography } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { useTranslation } from '../../../i18n';

export const UserProfileChangePasswordCardForm = () => {
  const changePasswordMutation = useChangePassword();
  const { t, tString } = useTranslation('user-profile');

  const form = useForm({
    onSubmit: async ({ value }) => {
      await changePasswordMutation.mutateAsync(
        { data: value },
        {
          onError: (errOut) => {
            if (errOut.type === 'https://metergrid.de/api-errors/model-validation-error') {
              addErrorsToFormFields({ form, modelValidationError: errOut });
              // Display Error message on both new password fields
              if (form.store.state.fieldMeta.new_password2.errors.length) {
                form.store.state.fieldMeta.new_password1.errors.push(
                  form.store.state.fieldMeta.new_password2.errors[0],
                );
              }
            } else {
              defaultErrorHandler(errOut);
            }
          },
        },
      );
    },
    defaultValues: {
      old_password: '',
      new_password1: '',
      new_password2: '',
    },
  });

  if (changePasswordMutation.isSuccess) {
    return (
      <Stack alignItems={'center'} padding={5}>
        <MGAnimatedIcon type={'success'} />
        <Typography>{t('password_reset_success_message')}</Typography>
      </Stack>
    );
  }
  return (
    <MGForm form={form}>
      <Stack spacing={3}>
        <Stack direction={'row'} spacing={3}>
          <MGFormFieldPassword name={'old_password'} form={form} label={tString('label_old_password')} />
        </Stack>
        <Stack direction={'row'} spacing={3}>
          <MGFormFieldPassword
            name={'new_password1'}
            form={form}
            label={tString('label_new_password')}
            TextFieldProps={{ autoComplete: 'new-password' }}
          />
          <MGFormFieldPassword
            name={'new_password2'}
            form={form}
            label={tString('label_new_password_repeat')}
            TextFieldProps={{ autoComplete: 'new-password' }}
          />
        </Stack>
        <MGFormSubmitButton buttonLabel={tString('btn_change_password')} form={form} />
      </Stack>
    </MGForm>
  );
};
