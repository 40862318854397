import { Box } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useAtom } from 'jotai/index';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshMessagesAtom } from '../../jotai/atoms';
import { Loader } from './Loader/Loader';

type Props = {
  pathToDjangoForm: string;
  redirectPath?: string;
  prefill?: { id: string; value: string | number }[];
  hideTitle?: boolean;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const DjangoForm = ({ pathToDjangoForm, onSuccess, onError, prefill = [], hideTitle }: Props) => {
  const [element, setElement] = useState<Element | null>(null);
  const [loading, setLoading] = useState(true);
  const [messageRefreshCounter, setMessageRefreshCounter] = useAtom(refreshMessagesAtom);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/iframe${pathToDjangoForm}`).then((res) => {
      createAndFormatElement(res.data);
    });
  }, []);

  // Prefill form with data
  useEffect(() => {
    const form = document.getElementsByTagName('form')[0];
    if (form) {
      for (const _prefillValue of prefill) {
        const input = form.querySelector(`[id="${_prefillValue.id}"]`) as HTMLInputElement;
        if (input) {
          if (input.type === 'select-one') {
            const option = input.querySelector(`option[value="${_prefillValue.value}"]`) as HTMLOptionElement;
            if (option) option.selected = true;
          } else {
            input.value = `${_prefillValue.value}`;
          }
        }
      }
    }
  }, [prefill, element]);

  const createAndFormatElement = (htmlAsString: string) => {
    const element = document.createElement('html');
    element.innerHTML = htmlAsString;

    if (element.getElementsByTagName('form').length > 0) {
      element.getElementsByClassName('btn-link hover-danger')[0].remove();
      if (hideTitle) {
        element.getElementsByClassName('main_title')[0].remove();
      }
      setElement(element.getElementsByClassName('react-usable-content')[0]);
    } else {
      onSuccess ? onSuccess() : navigate(-1);
    }
    setLoading(false);
  };

  const addOnSubmitHandlerToForm = useCallback(
    (element) => {
      if (element) {
        const form = element.getElementsByTagName('form')[0];
        if (!form) return;
        form.method = undefined;
        form.addEventListener('submit', (e) => {
          e.preventDefault();
          setLoading(true);
          const data = Object.fromEntries(new FormData(e.target).entries());
          axios
            .postForm(`/iframe${pathToDjangoForm}`, data)
            .then((res) => {
              createAndFormatElement(res.data);
            })
            .catch(onError)
            .finally(() => setMessageRefreshCounter(messageRefreshCounter + 1));
        });
      }
    },
    [element],
  );

  return (
    <Box sx={{ height: 'calc(100% - 50px)' }}>
      {!element?.innerHTML || loading ? (
        <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <Box
          paddingBottom={'20px'}
          ref={addOnSubmitHandlerToForm}
          dangerouslySetInnerHTML={{ __html: element.innerHTML }}
        />
      )}
    </Box>
  );
};
