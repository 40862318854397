import { MGCard, MGCardContent, MGCardHeader, MGCardTitle } from '@mg/ui/src/components/MGCard';
import { KeyRound } from 'lucide-react';
import { useTranslation } from '../../../i18n.tsx';
import { UserProfileChangePasswordCardForm } from './UserProfile.ChangePasswordCard.Form.tsx';

export const UserProfileChangePasswordCard = () => {
  const { t } = useTranslation('user-profile');
  return (
    <MGCard>
      <MGCardHeader leftHeader={<KeyRound />} />
      <MGCardTitle>{t('change_password')}</MGCardTitle>
      <MGCardContent>
        <UserProfileChangePasswordCardForm />
      </MGCardContent>
    </MGCard>
  );
};
