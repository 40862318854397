import { Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

type Props = {
  title?: ReactNode;
  text?: ReactNode;
  children: ReactElement;
  interactive?: boolean;
  disabled?: boolean;
} & Pick<
  TooltipProps,
  | 'open'
  | 'onClose'
  | 'disableFocusListener'
  | 'disableHoverListener'
  | 'disableTouchListener'
  | 'slotProps'
  | 'enterTouchDelay'
>;
export const MGTooltip = ({
  children,
  title,
  text,
  interactive = false,
  disabled = false,
  open = undefined,
  onClose,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  enterTouchDelay,
  slotProps,
}: Props) => {
  if (disabled) {
    return children;
  }

  return (
    <Tooltip
      placement={'top'}
      arrow
      disableInteractive={!interactive}
      enterTouchDelay={enterTouchDelay}
      open={open}
      title={
        <Stack sx={{ paddingX: '10px', paddingY: '15px', gap: '10px' }}>
          {title && (
            <Typography
              variant={'small'}
              sx={{
                fontWeight: 'bold',
              }}
            >
              {title}
            </Typography>
          )}
          {text && <Typography variant={'extraSmall'}>{text}</Typography>}
        </Stack>
      }
      onClose={onClose}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      slotProps={slotProps}
    >
      {children}
    </Tooltip>
  );
};
