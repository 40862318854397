import { Avatar, Card, Stack, Typography } from '@mui/material';
import { useUserInfo } from '../../../helpers/userInfo';

export const UserProfileUserCard = () => {
  const [userInfo] = useUserInfo();
  return (
    <Card sx={{ padding: 3 }}>
      <Stack direction={'row'} alignItems={'center'} spacing={3}>
        <Avatar sx={{ bgcolor: 'primary.light', color: 'primary.main', width: '60px', height: '60px' }} />

        <Stack spacing={1}>
          <Typography variant={'h3'} color={'text.secondary'}>
            {userInfo!.first_name} {userInfo!.last_name}
          </Typography>
          <Stack direction={'row'} spacing={4}>
            <Typography variant={'extraSmall'}>{userInfo!.email}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
