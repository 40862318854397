import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { palette } from '@mg/ui/src/styles';
import { Box, Stack } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useFlags } from '../../../feature-flags';
import { useTranslation } from '../../../i18n';
import { BillingProvider } from './Billing.Provider';
import { BillingStepper } from './Billing.Stepper';
import { NavigateWithSearch } from './NavigateWithSearch';
import { BillingBillingPeriod } from './steps/BillingPeriod/Billing.BillingPeriod';
import { BillingDetailsPerCustomer } from './steps/DetailsPerCustomer/Billing.DetailsPerCustomer';
import { BillingMeteringData } from './steps/MeteringData/Billing.MeteringData';

export const Billing = () => {
  const { t } = useTranslation('billing');

  return (
    <BillingProvider>
      <MGPageLayout title={t('page_title')} subtitle={t('page_subtitle')} center sx={{ marginTop: 5 }}>
        <Stack spacing={5}>
          <BillingStepper />
          <Routes>
            <Route path={'/'} element={<NavigateWithSearch replace to="intervals" />} />
            <Route path={'/intervals'} element={<BillingBillingPeriod />} />
            <Route path={'/metering-data'} element={<BillingMeteringData />} />
            <Route path={'/details-per-customer'} element={<BillingDetailsPerCustomer />} />
          </Routes>
        </Stack>
        <BillingDebugIndicator />
      </MGPageLayout>
    </BillingProvider>
  );
};

const BillingDebugIndicator = () => {
  const { billingDebug: isDebug } = useFlags();

  if (!isDebug) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        fontSize: 10,
        p: 0.5,
        backgroundColor: palette.error.main,
        color: palette.error.contrastText,
        opacity: 0.6,
        zIndex: 9999,
      }}
    >
      BILLING DEBUG MODE ACTIVE
    </Box>
  );
};
