import {
  deliverableDocumentType,
  InvoiceSchema,
  useGetInvoicePdfsAsZip,
  useListInvoicesInfinite,
} from '@mg/api-wrappers/src/api-internal';
import { MGCustomerWithAvatar } from '@mg/ui/src/components/MGCustomerWithAvatar';
import { Box, Chip, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter';
import { useTranslation } from '../../../i18n';
import { filterValueAtom } from '../../../jotai/actionBar';
import { presentDate } from '../../../presenters/date';
import { presentEuro } from '../../../presenters/number';
import { DataGridCard } from '../../shared/DataGridCard/DataGridCard';
import { useGridApi } from '../../shared/DataGridCard/useGridApi';
import { DocumentsInvoiceStatus } from './Documents.InvoiceStatus.tsx';
import { DocumentsInvoiceTableFilterForm } from './Documents.InvoiceTable.FilterForm';
import { DocumentsInvoiceTableRowActionMenu } from './Documents.InvoiceTable.RowActionMenu';
import { DocumentsRowAction } from './Documents.RowAction';
import { DocumentsStatusAndQuickaction } from './Documents.StatusAndQuickaction';
import { useSetActionBarButtons } from './useSetActionBarButtons';

type Props = {
  customerId?: number | null;
};
export const DocumentsInvoiceTable = ({ customerId }: Props) => {
  const { t, tString } = useTranslation('documents');
  const currentProjectId = useSelectedProjectsFilter();
  const { dataGridApiRef } = useGridApi();
  const getInvoiceAsZipQuery = useGetInvoicePdfsAsZip(
    { ...currentProjectId, customer_id: customerId },
    { query: { enabled: false } },
  );
  const [filter] = useAtom(filterValueAtom);

  useSetActionBarButtons({
    getAsZipQuery: getInvoiceAsZipQuery,
    documentType: deliverableDocumentType.invoice,
  });

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'number',
      headerName: tString('invoice_number'),
      flex: 0.4,

      renderCell: (params) => {
        if (params.row.state === 'draft') {
          return t('invoice_draft');
        }
        return `${params.row.series}-${params.row.number}`;
      },
    },
    {
      field: 'cancels',
      headerName: t('invoice_annotation'),
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        if (!dataGridApiRef.current?.apiLoaded) return;

        // FIXME: This does not always work, because canceled_by and cancels are not always available in the datagrid. Instead we should fetch the data from the API.
        const datagridRowsMap = dataGridApiRef.current.getRowModels();
        const canceledByInvoice = params.row.canceled_by ? datagridRowsMap.get(params.row.canceled_by) : null;
        const cancelsInvoice = params.row.cancels ? datagridRowsMap.get(params.row.cancels) : null;

        return (
          <>
            {params.row.state === 'canceled' && (
              <Tooltip
                arrow
                placement={'right'}
                title={
                  !canceledByInvoice
                    ? ''
                    : 'Wurde von folgender Rechnung storniert: ' +
                      `${canceledByInvoice?.series}-${canceledByInvoice?.number}`
                }
              >
                <Chip
                  color={'error'}
                  variant={'soft'}
                  sx={{ width: '80px', borderRadius: '8px' }}
                  size={'small'}
                  label={t(params.row.state)}
                />
              </Tooltip>
            )}
            {params.row.cancels && (
              <Tooltip
                arrow
                placement={'right'}
                title={
                  cancelsInvoice
                    ? 'Stornierte Rechnung: ' + `${cancelsInvoice.series}-${cancelsInvoice.number}`
                    : undefined
                }
              >
                <Chip
                  color={'primary'}
                  variant={'soft'}
                  sx={{ width: '100px', borderRadius: '8px' }}
                  size={'small'}
                  label={'Storno-R.'}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'customer',
      headerName: tString('customer'),
      sortable: false,
      flex: 0.7,
      renderCell: (params) => {
        return <MGCustomerWithAvatar customer={params.row.customer} />;
      },
    },
    {
      field: 'issue_date',
      headerName: tString('created_at'),
      flex: 0.3,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'period_start',
      sortable: false,
      headerName: tString('period_start'),
      flex: 0.3,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'period_end',
      sortable: false,
      headerName: tString('period_end'),
      flex: 0.3,
      valueGetter: (value) => presentDate(value),
    },
    {
      field: 'total',
      headerName: tString('total'),
      sortable: true,
      sortKey: '_total__gross',
      flex: 0.3,
      valueGetter: (value) => presentEuro(value),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 0,
      renderCell: (params) =>
        dataGridApiRef.current?.apiLoaded && (
          <DocumentsRowAction
            document={params.row}
            documentType={deliverableDocumentType.invoice}
            queryKey={dataGridApiRef.current.getQueryKey()}
          >
            {params.row.category == 'regular invoice' &&
            params.row.invoice_billing_task != null && // backward compatibility, for existing invoices
            params.row.invoice_billing_task?.status != 'success' ? (
              <DocumentsInvoiceStatus invoice={params.row} />
            ) : (
              <DocumentsStatusAndQuickaction />
            )}
            <DocumentsInvoiceTableRowActionMenu />
          </DocumentsRowAction>
        ),
    },
  ] as GridColDef<InvoiceSchema>[];

  return (
    <Box sx={{ height: '100%' }}>
      <DataGridCard
        height={'calc(100% - 50px)'}
        searchPlaceholder={tString('search_placeholder') as string}
        columns={columns}
        selectable
        reactQueryHook={useListInvoicesInfinite}
        reactQueryHookParams={{
          ...currentProjectId,
          ...filter,
          document_type: deliverableDocumentType.invoice,
          include_customer: true,
          include_pdf: true,
          customer_id: customerId,
        }}
        reactQueryHookQueryOptions={{
          meta: {
            refetchForActiveTasks: true,
          },
        }}
        datagridProps={{
          columnVisibilityModel: { id: false },
        }}
        defaultSort={'-id'}
        filterModalContent={<DocumentsInvoiceTableFilterForm />}
      />
    </Box>
  );
};
