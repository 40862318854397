import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListInvoicesQueryResponse, ListInvoicesQueryParams, ListInvoices422 } from '../types/ListInvoices.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listInvoicesInfiniteQueryKey = (params: ListInvoicesQueryParams) => [{ url: '/api-internal/invoices/' }, ...(params ? [params] : [])] as const

export type ListInvoicesInfiniteQueryKey = ReturnType<typeof listInvoicesInfiniteQueryKey>

/**
 * @summary List Invoices
 * {@link /api-internal/invoices/}
 */
async function listInvoices(params: ListInvoicesQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListInvoicesQueryResponse, ResponseErrorConfig<ListInvoices422>, unknown>({
    method: 'GET',
    url: `/api-internal/invoices/`,
    params,
    ...requestConfig,
  })
  return res.data
}

export function listInvoicesInfiniteQueryOptions(params: ListInvoicesQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listInvoicesInfiniteQueryKey(params)
  return infiniteQueryOptions<ListInvoicesQueryResponse, ListInvoices422, ListInvoicesQueryResponse, typeof queryKey, number>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListInvoicesQueryParams['page']
      }
      return listInvoices(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Invoices
 * {@link /api-internal/invoices/}
 */
export function useListInvoicesInfinite<
  TData = InfiniteData<ListInvoicesQueryResponse>,
  TQueryData = ListInvoicesQueryResponse,
  TQueryKey extends QueryKey = ListInvoicesInfiniteQueryKey,
>(
  params: ListInvoicesQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListInvoicesQueryResponse, ListInvoices422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listInvoicesInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listInvoicesInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListInvoices422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}