import { TanstackForm } from './MGForm';

/*
 * Needs to be redefined since we shoudlnt have dependencies to the generated API hooks.
 * */
interface ModelValidationErrorOut {
  errors: {
    location?: string[];
    message?: string;
  }[];
}

interface Props {
  form: TanstackForm;
  modelValidationError: ModelValidationErrorOut;
}

export const addErrorsToFormFields = ({ form, modelValidationError }: Props) => {
  if (modelValidationError.errors && modelValidationError.errors.length) {
    for (const _error of modelValidationError.errors) {
      if (_error.location) {
        for (const _location of _error.location) {
          form.store.state.fieldMeta[_location].errors.push(_error.message);
        }
      }
    }
  }
};

export const resetErrorForFields = (form, fields: string[] = []) => {
  Object.keys(form.store.state.fieldMeta)
    .filter((key) => fields.length === 0 || fields.includes(key))
    .forEach((fieldKey) => {
      form.store.state.fieldMeta[fieldKey].errors = [];
    });
};
