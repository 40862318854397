import { MGPageLayout } from '@mg/ui/src/components/MGPageLayout';
import { Stack } from '@mui/material';
import { useTranslation } from '../../../i18n.tsx';
import { UserProfileChangePasswordCard } from './UserProfile.ChangePasswordCard.tsx';
import { UserProfileUserCard } from './UserProfile.UserCard.tsx';

export const UserProfile = () => {
  const { t } = useTranslation('user-profile');
  return (
    <MGPageLayout title={t('page_title')}>
      <Stack spacing={4}>
        <UserProfileUserCard />
        <UserProfileChangePasswordCard />
      </Stack>
    </MGPageLayout>
  );
};
