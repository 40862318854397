import { useGetCurrentUser, useGetProject, useListMessages } from '@mg/api-wrappers/src/api-internal';
import { Container } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom, useSetAtom } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Accounting } from '../components/pages/Accounting/Accounting';
import { Billing } from '../components/pages/Billing/Billing';
import { Contract } from '../components/pages/Contract/Contract';
import { CreateLetter } from '../components/pages/CreateLetter/CreateLetter';
import { CustomerDetails } from '../components/pages/CustomerDetails/CustomerDetails';
import { Customers } from '../components/pages/Customers/Customers';
import { Dashboard } from '../components/pages/Dashboard/Dashboard';
import { Onboarding } from '../components/pages/Dashboard/Onboarding/Onboarding';
import { Documents } from '../components/pages/Documents/Documents';
import { LevyStructureLevies } from '../components/pages/LevyStructureLevies/LevyStructureLevies';
import { LevyStructures } from '../components/pages/LevyStructures/LevyStructures';
import { MetergridPlusPage } from '../components/pages/MetergridPlus/MetergridPlus';
import { Metering } from '../components/pages/Metering/Metering';
import { MeteringDetails } from '../components/pages/MeteringDetails/MeteringDetails';
import { MeteringHistory } from '../components/pages/MeteringHistory/MeteringHistory';
import { Plans } from '../components/pages/Plans/Plans';
import { Projects } from '../components/pages/Projects/Projects';
import { UserProfile } from '../components/pages/UserProfile/UserProfiile.tsx';
import { DjangoPageIFrame } from '../components/shared/DjangoPageIFrame';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { useRefreshQueriesWithRunningTasks } from '../hooks/useRefreshQueriesWithRunningTasks';
import { currentProjectIdAtom, refreshMessagesAtom } from '../jotai/atoms';

type Route = {
  path: string;
  element: () => ReactNode;
  type: 'main' | 'subpage';
  isDjango?: boolean;
  children?: Route[];
};
export const MainContainer = ({ sidebarWidth }) => {
  const location = useLocation();
  const currentProjectQuery = useGetProject(useCurrentProject());

  const onboardingBaseDataCompleted = currentProjectQuery.data?.onboarding_base_data_completed;
  const onboadingCompleted = currentProjectQuery.data?.onboarding_completed;
  const userInfoQuery = useGetCurrentUser();

  const showOnboarding = !onboardingBaseDataCompleted || !onboadingCompleted;

  const routes: Route[] = [
    { path: '/', type: 'main', element: showOnboarding ? Onboarding : Dashboard },
    { path: '/metergridplus', type: 'subpage', element: MetergridPlusPage },
    { path: '/metering', type: 'main', element: Metering },
    { path: '/metering/data', type: 'subpage', element: MeteringHistory },
    { path: '/metering/:id', type: 'subpage', element: MeteringDetails },
    { path: '/transactions', type: 'main', element: Accounting },
    { path: '/projects', type: 'main', element: Projects },
    { path: '/levystructures', type: 'main', element: LevyStructures },
    { path: '/levystructures/:id/levies', type: 'subpage', element: LevyStructureLevies },
    { path: '/plans', type: 'main', element: Plans },
    { path: '/contract', type: 'subpage', element: Contract },
    { path: '/documents/letter/create', type: 'subpage', element: CreateLetter },
    { path: '/documents/letter/:letterId/edit', type: 'subpage', element: CreateLetter },
    { path: '/documents', type: 'main', element: Documents },
    { path: '/customers', type: 'main', element: Customers },
    { path: '/customers/:id', type: 'subpage', element: CustomerDetails },
    { path: '/customers/create', type: 'subpage', element: DjangoPageIFrame },
    { path: '/customers/:id/edit/', type: 'main', element: DjangoPageIFrame },
    { path: 'customers/billing/*', type: 'main', element: Billing },
    { path: '/profile', type: 'main', element: UserProfile },
  ];

  const messageQuery = useListMessages({ query: { enabled: false } });
  const [refreshMessages] = useAtom(refreshMessagesAtom);

  useRefreshQueriesWithRunningTasks();

  useEffect(() => {
    messageQuery.refetch().then((messageResponse) => {
      if (messageResponse.fetchStatus !== 'idle') {
        return;
      }
      messageResponse.data?.forEach((message) => {
        if (message.level === 'info' || message.level === 'debug') {
          toast.info(message.message, { toastId: message.message });
        } else if (message.level === 'success') {
          toast.success(message.message, { toastId: message.message });
        } else if (message.level === 'warning') {
          toast.warn(message.message, { toastId: message.message });
        } else if (message.level === 'error') {
          toast.error(message.message, { toastId: message.message });
        }
      });
    });
  }, [window.location.href, refreshMessages]);

  const setCurrentProjectId = useSetAtom(currentProjectIdAtom);
  useEffect(() => {
    userInfoQuery.refetch().then(({ data, isSuccess }) => {
      if (isSuccess) setCurrentProjectId(data.current_project_id);
    });
  }, [window.location.href]);

  const renderRoutes = (routes: Route[]) => {
    return routes.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={
          <AnimatedElement type={route.type}>
            <route.element />
          </AnimatedElement>
        }
      >
        {renderRoutes(route.children || [])}
      </Route>
    ));
  };

  return (
    <Container
      sx={{
        backgroundColor: '#F6F5F3',
        position: 'absolute',
        top: 0,
        left: sidebarWidth || '280px',
        height: '100vh',
        maxWidth: `calc(100vw - ${sidebarWidth || '280px'}) !important`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        paddingX: '0px !important',
      }}
    >
      <AnimatePresence mode={'wait'}>
        <Routes location={location} key={location.pathname}>
          {renderRoutes(routes)}
          <Route path={'/customers/electricity_price_break_quota'} element={<DjangoPageIFrame />} />
          {/* Catch all other routes and display the django content in an iframe */}
          <Route path={'*'} element={<DjangoPageIFrame />} />
        </Routes>
      </AnimatePresence>
    </Container>
  );
};

const AnimatedElement = (props) => {
  if (props.type === 'main') {
    return (
      <motion.div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2, type: 'linear' } }}
        exit={{ opacity: 0, transition: { duration: 0.1, type: 'linear' } }}
        {...props}
      />
    );
  }
  return (
    <motion.div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        paddingRight: '35px',
      }}
      initial={{ x: 800, opacity: 0 }}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.4, type: 'linear' } }}
      exit={{ x: 800, opacity: 0, transition: { duration: 0.2, type: 'linear' } }}
      {...props}
    />
  );
};
