import { toast } from 'react-toastify';

type ErrorOut = {
  type: string;
  title: string;
};

export const isErrorOut = (error: unknown): error is ErrorOut => {
  return typeof error === 'object' && error !== null && 'type' in error && 'title' in error;
};

type PublicErrorOut = ErrorOut & {
  type: 'https://metergrid.de/api-errors/public-error';
  error: {
    message: string;
  };
};

const isPublicErrorOut = (error: unknown): error is PublicErrorOut => {
  return isErrorOut(error) && error.type === 'https://metergrid.de/api-errors/public-error';
};

export const defaultErrorHandler = (error: unknown) => {
  if (isErrorOut(error)) {
    if (isPublicErrorOut(error)) {
      toast.error(error.error.message);
    } else {
      toast.error(error.title);
    }
  } else {
    toast.error('Ein Fehler ist aufgetreten');
  }
};
