import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ListProjectsQueryResponse, ListProjectsQueryParams, ListProjects422 } from '../types/ListProjects.ts'
import type { InfiniteData, QueryKey, InfiniteQueryObserverOptions, UseInfiniteQueryResult } from '@tanstack/react-query'
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'

export const listProjectsInfiniteQueryKey = (params?: ListProjectsQueryParams) => [{ url: '/api-internal/projects/' }, ...(params ? [params] : [])] as const

export type ListProjectsInfiniteQueryKey = ReturnType<typeof listProjectsInfiniteQueryKey>

/**
 * @summary List Projects
 * {@link /api-internal/projects/}
 */
async function listProjects(params?: ListProjectsQueryParams, config: Partial<RequestConfig> & { client?: typeof client } = {}) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<ListProjectsQueryResponse, ResponseErrorConfig<ListProjects422>, unknown>({
    method: 'GET',
    url: `/api-internal/projects/`,
    params,
    ...requestConfig,
  })
  return res.data
}

export function listProjectsInfiniteQueryOptions(params?: ListProjectsQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = listProjectsInfiniteQueryKey(params)
  return infiniteQueryOptions<ListProjectsQueryResponse, ListProjects422, ListProjectsQueryResponse, typeof queryKey, number>({
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      config.signal = signal

      if (params) {
        params['page'] = pageParam as unknown as ListProjectsQueryParams['page']
      }
      return listProjects(params, config)
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => (Array.isArray(lastPage) && lastPage.length === 0 ? undefined : lastPageParam + 1),
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam <= 1 ? undefined : firstPageParam - 1),
  })
}

/**
 * @summary List Projects
 * {@link /api-internal/projects/}
 */
export function useListProjectsInfinite<
  TData = InfiniteData<ListProjectsQueryResponse>,
  TQueryData = ListProjectsQueryResponse,
  TQueryKey extends QueryKey = ListProjectsInfiniteQueryKey,
>(
  params?: ListProjectsQueryParams,
  options: {
    query?: Partial<InfiniteQueryObserverOptions<ListProjectsQueryResponse, ListProjects422, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? listProjectsInfiniteQueryKey(params)

  const query = useInfiniteQuery({
    ...(listProjectsInfiniteQueryOptions(params, config) as unknown as InfiniteQueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<InfiniteQueryObserverOptions, 'queryKey'>),
  }) as UseInfiniteQueryResult<TData, ListProjects422> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}