import { changePasswordInSchema } from './changePasswordInSchema.ts'
import { modelValidationErrorOutSchema } from './modelValidationErrorOutSchema.ts'
import { paramValidationErrorOutSchema } from './paramValidationErrorOutSchema.ts'
import { z } from 'zod'

/**
 * @description Created
 */
export const changePassword201Schema = z.unknown()

/**
 * @description Unprocessable Entity
 */
export const changePassword422Schema = z.union([z.lazy(() => paramValidationErrorOutSchema), z.lazy(() => modelValidationErrorOutSchema)])

export const changePasswordMutationRequestSchema = z.lazy(() => changePasswordInSchema)

export const changePasswordMutationResponseSchema = z.lazy(() => changePassword201Schema)