import { axiosInstance } from '@mg/api-wrappers/src/client';
import { logout } from '../helpers/logout';

const logoutInterceptor = (error) => {
  const requestUrl = error.request?.responseURL.split('/api-internal/')[1];
  if ((requestUrl !== 'auth/token' && error.response?.status === 401) || error.response?.status === 403) {
    logout(window.location.pathname);
  }
  throw error;
};

const authInterceptor = (request) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};
axiosInstance.interceptors.request.use((r) => authInterceptor(r));
axiosInstance.interceptors.response.use((r) => r, logoutInterceptor);
