import { Button, Stack, Typography } from '@mui/material';
import { Files } from 'lucide-react';
import { useTranslation } from '../../../i18n';
import { DashboardCard } from './Dashboard.Card';

export const DashboardHelpSection = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Stack spacing={2}>
      <Typography variant={'small'}>{t('help_section_title')}</Typography>
      <Stack direction={'row'} spacing={1} minHeight={'200px'}>
        <DashboardCard
          title={t('help_section_setup_title')}
          subtitle={t('help_section_setup_subtitle')}
          icon={<Files />}
          button={
            <Button
              variant={'contained'}
              color={'white'}
              href={'https://www.metergrid.de/help-center/anleitungen#projekt'}
              target={'_blank'}
            >
              {t('help_section_setup_button')}
            </Button>
          }
        />
        <DashboardCard
          title={t('help_section_change_plans_title')}
          subtitle={t('help_section_change_plans_subtitle')}
          icon={<Files />}
          button={
            <Button
              variant={'contained'}
              color={'white'}
              href={'https://www.metergrid.de/help-center/anleitungen#tarifwechsel'}
              target={'_blank'}
            >
              {t('help_section_change_plans_button')}
            </Button>
          }
        />
        <DashboardCard
          title={t('help_section_malo_title')}
          subtitle={t('help_section_malo_subtitle')}
          icon={<Files />}
          button={
            <Button
              variant={'contained'}
              color={'white'}
              href={'https://www.metergrid.de/help-center/faq'}
              target={'_blank'}
            >
              {t('help_section_malo_button')}
            </Button>
          }
        />
      </Stack>
    </Stack>
  );
};
