import { InvoiceSchema } from '@mg/api-wrappers/src/api-internal';
import { MGLoader } from '@mg/ui/src/components/MGLoader/MGLoader';
import { MGTooltip } from '@mg/ui/src/components/MGTooltip';
import { Box, useTheme } from '@mui/material';
import { FileX2Icon } from 'lucide-react';
import { useTranslation } from '../../../i18n';

const iconProps = { size: 18 };

export const DocumentsInvoiceStatus = ({ invoice }: { invoice: InvoiceSchema }) => {
  return (
    <Box sx={{ width: '36px', textAlign: 'center', flexShrink: 0, flexGrow: 0 }}>
      <Inner invoice={invoice} />
    </Box>
  );
};

const Inner = ({ invoice }: { invoice: InvoiceSchema }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('documents');

  if (invoice.invoice_billing_task?.status == 'failure') {
    return (
      <MGTooltip
        title={t('invoice_status_generate_error_title')}
        text={t('invoice_status_generate_error_subtitle')}
      >
        <FileX2Icon color={palette.warning.main} {...iconProps} />
      </MGTooltip>
    );
  } else {
    return (
      <MGTooltip
        title={t('invoice_status_generate_loading_title')}
        text={t('invoice_status_generate_loading_subtitle')}
      >
        <div>
          <MGLoader size="20px" />
        </div>
      </MGTooltip>
    );
  }
};
