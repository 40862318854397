import { customerNestedSchemaSchema } from './customerNestedSchemaSchema.ts'
import { pdfNestedSchemaSchema } from './pdfNestedSchemaSchema.ts'
import { trackedTaskNestedSchemaSchema } from './trackedTaskNestedSchemaSchema.ts'
import { z } from 'zod'

export const invoiceSchemaSchema = z.object({
  cancel_date: z.union([z.string().date(), z.null()]).optional(),
  canceled_by: z.union([z.number().int(), z.null()]).optional(),
  cancels: z.union([z.number().int(), z.null()]).optional(),
  category: z.string().max(255).default('regular invoice'),
  customer: z.union([z.lazy(() => customerNestedSchemaSchema), z.null()]).optional(),
  deliver_to_customer_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  delivered_to_customer: z.boolean(),
  delivery_in_progress: z.boolean(),
  due_date: z.union([z.string().date(), z.null()]).optional(),
  generate_pdf_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  id: z.number().int(),
  invoice_billing_task: z.union([z.lazy(() => trackedTaskNestedSchemaSchema), z.null()]).optional(),
  issue_date: z.union([z.string().date(), z.null()]).optional(),
  number: z.union([z.number().int(), z.null()]).optional(),
  paid_date: z.union([z.string().date(), z.null()]).optional(),
  pdf: z.union([z.lazy(() => pdfNestedSchemaSchema), z.null()]).optional(),
  period_end: z.union([z.string().datetime(), z.null()]).optional(),
  period_start: z.union([z.string().datetime(), z.null()]).optional(),
  provider: z.union([z.number().int(), z.null()]).optional(),
  series: z.union([z.string(), z.null()]).optional(),
  state: z.string().max(10).default('draft').describe('The state the invoice is in.'),
  total: z.union([z.number(), z.null()]).optional(),
})