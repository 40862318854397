import client from '../../client.ts'
import type { RequestConfig } from '../../client.ts'
import type { ChangePasswordMutationRequest, ChangePasswordMutationResponse, ChangePassword422 } from '../types/ChangePassword.ts'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const changePasswordMutationKey = () => [{ url: '/api-internal/users/current/change-password' }] as const

export type ChangePasswordMutationKey = ReturnType<typeof changePasswordMutationKey>

/**
 * @summary Change Password
 * {@link /api-internal/users/current/change-password}
 */
async function changePassword(data: ChangePasswordMutationRequest, config: Partial<RequestConfig<ChangePasswordMutationRequest>> = {}) {
  const res = await client<ChangePasswordMutationResponse, ChangePassword422, ChangePasswordMutationRequest>({
    method: 'POST',
    url: `/api-internal/users/current/change-password`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @summary Change Password
 * {@link /api-internal/users/current/change-password}
 */
export function useChangePassword(
  options: {
    mutation?: UseMutationOptions<ChangePasswordMutationResponse, ChangePassword422, { data: ChangePasswordMutationRequest }>
    client?: Partial<RequestConfig<ChangePasswordMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? changePasswordMutationKey()

  return useMutation<ChangePasswordMutationResponse, ChangePassword422, { data: ChangePasswordMutationRequest }>({
    mutationFn: async ({ data }) => {
      return changePassword(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}