import { useExportMeasuringPointData } from '@mg/api-wrappers/src/api-internal';
import { MGActionMenu, MGActionMenuItemProps } from '@mg/ui/src/components/MGActionMenu';
import { Button, Stack, useTheme } from '@mui/material';
import { ChevronDown, CirclePlus, FileDown, FileUp, History } from 'lucide-react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { downloadFile } from '../../../helpers/downloadFile';
import { invalidateAllQueries } from '../../../helpers/invalidateAllQueries';
import { useSelectedProjectsFilter } from '../../../hooks/useSelectedProjectsFilter.ts';
import { useTranslation } from '../../../i18n';
import { DjangoFormModal } from '../../modals/DjangoFormModal';

export const MeteringPageActionMenu = () => {
  const [newPanelModalOpen, setNewPanelModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation('metering');
  const projectFilter = useSelectedProjectsFilter();
  const exportMeteringDataQuery = useExportMeasuringPointData(
    { project_id: projectFilter.project_id },
    { query: { enabled: false } },
  );

  const menuItems: MGActionMenuItemProps[] = [
    {
      text: t('create_new_panel'),
      icon: <CirclePlus size={'20'} />,
      onClick: () => setNewPanelModalOpen(true),
    },
    {
      text: t('import_measuring_data'),
      icon: <FileUp size={'20'} />,
      onClick: () => {
        window.location.href = 'import/metering';
      },
    },
    {
      text: t('export_measuring_data'),
      icon: <FileDown size={'20'} />,
      onClick: () => downloadFile(exportMeteringDataQuery, { project_id: projectFilter.project_id }),
    },
  ];

  return (
    <>
      <Stack direction="row" spacing={theme.spacing(1)}>
        <Button
          startIcon={<History size={20} />}
          variant={'outlined'}
          size={'large'}
          component={NavLink}
          to="/metering/data/"
        >
          {t('metering_history')}
        </Button>
        <MGActionMenu
          menuItems={menuItems}
          spacing={15}
          buttonComponent={
            <Button
              variant={'contained'}
              size={'large'}
              endIcon={<ChevronDown style={{ marginLeft: '10px' }} />}
            >
              {t('action_menu_title')}
            </Button>
          }
        />
      </Stack>

      <DjangoFormModal
        pathToDjangoForm={`/metering/panels/create`}
        title={t('create_new_panel')}
        open={newPanelModalOpen}
        handleToggle={() => {
          setNewPanelModalOpen(!newPanelModalOpen);
        }}
        onSuccess={() => {
          setNewPanelModalOpen(false);
          invalidateAllQueries();
        }}
      />
      <DjangoFormModal
        pathToDjangoForm={`/import/metering`}
        title={t('import_measuring_data')}
        open={importModalOpen}
        handleToggle={() => {
          setImportModalOpen(!importModalOpen);
        }}
        onSuccess={() => {
          setImportModalOpen(false);
          invalidateAllQueries();
        }}
        BodyStyle={{ paddingTop: '40px' }}
      />
    </>
  );
};
