import { Typography } from '@mui/material';
import { ValidationError } from '@tanstack/react-form';

type Props = {
  errors: ValidationError[];
  errorTextSide?: 'left' | 'right';
};
export const MGFieldError = ({ errors = [], errorTextSide = 'right' }: Props) => {
  if (!errors.length) {
    return null;
  }
  return (
    <Typography
      color={'error'}
      variant={'extraSmall'}
      justifyContent={errorTextSide === 'right' ? 'flex-end' : 'flex-start'}
      alignItems={'center'}
      display={'flex'}
    >
      {errors.join(', ')}
    </Typography>
  );
};
