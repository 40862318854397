import { Stack } from '@mui/material';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const DjangoPageIFrame = () => {
  const location = useLocation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleIFrameLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    const iframe = event.target as HTMLIFrameElement;
    const iframeDocument = iframe.contentWindow?.document;

    // Redirect to /iframe if not already there. Required to catch redirects within the iframe, to prevent iframe-ception
    if (!iframeDocument?.location.pathname.startsWith('/iframe') && !location.pathname.startsWith('/api/'))
      iframe.src = `/iframe${iframeDocument?.location.pathname}` + iframeDocument?.location.search;

    // Update main app location to the iframe location if redirects happen inside the iframe
    if (iframeDocument?.location.pathname.split('/iframe')[1] !== location.pathname + location.search) {
      //navigate(iframeDocument!.location.pathname.split('/iframe')[1], { replace: true });
      window.history.replaceState(null, '', iframeDocument!.location.pathname.split('/iframe')[1]);
    }

    // Update all links to taget =_top so the redirect happens outside the iFrame
    const links = iframeDocument?.querySelectorAll('a') || [];
    links.forEach((link) => {
      link.setAttribute('target', '_top');
    });
  };

  const src = location.pathname.startsWith('/api/')
    ? location.pathname
    : `/iframe${location.pathname}` + location.search;

  return (
    <>
      <Stack sx={{ height: '100vh', width: '100%' }}>
        <iframe
          ref={iframeRef}
          onLoad={handleIFrameLoad}
          style={{
            marginTop: '0',
            height: '100%',
            width: '100%',
            border: 'none',
          }}
          src={src}
        />
      </Stack>
    </>
  );
};
