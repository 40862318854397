import { Box, IconButton, InputAdornment, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { ValidationError } from '@tanstack/react-form';
import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';
import { MGFieldError } from './MGField.Error';

type Props = {
  name: string;
  value: string;
  label?: string | null;
  errors: ValidationError[];
  onChange: InputBaseProps['onChange'];
  onBlur: InputBaseProps['onBlur'];
  TextFieldProps?: TextFieldProps;
};

export const MGFieldPassword = ({
  name,
  value,
  label,
  errors = [],
  onChange,
  onBlur,
  TextFieldProps,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack spacing={1} width={'100%'}>
      {label && (
        <Typography variant={'small'} fontWeight={300}>
          {label}
        </Typography>
      )}
      <Stack>
        <TextField
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={errors.length > 0}
          type={showPassword ? 'text' : 'password'}
          autoComplete={'current-password'}
          {...TextFieldProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box height={'12px'}>
          <MGFieldError errors={errors} />
        </Box>
      </Stack>
    </Stack>
  );
};
